/* eslint-disable no-case-declarations */
import LennaMessageType from "@/helpers/messagetype.helper";
// import Encryptor from "@/helpers/encryptor.helper";
import Toast from "@/helpers/toast.helper";
import Hashids from "hashids";
import { httpClientOmni } from "@/libraries";
import {
  assignIn,
  find,
  findIndex,
  isUndefined,
  isNull,
  some,
  remove,
  cloneDeep,
} from "lodash";
import {
  ConversationService,
  RoomService,
  LivechatService,
  AgentService,
} from "@/services";

const state = {
  roomList: [],
  selectedRoom: null,
  unreadMessage: null,
  unservedRoom: [],
  current_status: "",
  selected_channels: [],
  selected_business_unit: [],
  selected_ticket: [],
  selected_tags: [],
  search_keyword: "",
  state: [],
  isAgent: false,
  countRoomByStatus: {
    live: 0,
    request: 0,
    resolved: 0,
  },
  count_chat_by_status: {
    all: 0,
    request: 0,
    live: 0,
    resolved: 0,
    bot: 0,
  },
  scrolling: false,
  session: {},
  room_link: null,
  request_livecall: {},
  broadcastList: {},
  filterRoom: {},
  customer_info: {},
  freshChat: "",
  showEmoji: false,
  is_listening: false,
  agent_list: [],
  group_list: [],
  select_once: false,
};

const getters = {
  sortedRoomList(state, _, rootState) {
    // check is agent
    if (rootState.accessibility.isAgent) {
      return state.roomList
        .filter((each_room) => {
          if (
            state.current_status == "resolved" ||
            state.current_status == "live"
          ) {
            if (each_room.livechat == null) {
              return false;
            } else {
              return (
                each_room.livechat.handle_by ==
                  rootState.localStorage.decodedUserId &&
                each_room.livechat.status == state.current_status
              );
            }
          } else {
            if (each_room.livechat == null) {
              return true;
            } else {
              return false;
            }
          }

          // if (state.selected_channels.length > 0) {
          //   return state.selected_channels.includes(each_room.channel.name);
          // }

          // if (state.search_keyword.length > 0) {
          //   return each_room.created_by.name
          //     .toLowerCase()
          //     .match(state.search_keyword);
          // }
        })
        .sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
    }
    return state.roomList
      .filter((each_room) => {
        if (state.current_status == "") {
          return true;
        } else if (
          state.current_status == "resolved" ||
          state.current_status == "live" ||
          state.current_status == "request"
        ) {
          if (each_room.livechat == null) {
            return false;
          } else {
            return each_room.livechat.status == state.current_status;
          }
        } else {
          if (each_room.livechat == null) {
            return true;
          } else {
            return false;
          }
        }

        // if (state.selected_channels.length > 0) {
        //   return state.selected_channels.includes(each_room.channel.name);
        // }

        // if (state.search_keyword.length > 0) {
        //   return each_room.created_by.name
        //     .toLowerCase()
        //     .match(state.search_keyword);
        // }
      })
      .sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
  },
  roomList(state) {
    return state.roomList;
  },
  filterRoom(state) {
    return state.filterRoom;
  },
  selectedRoom(state) {
    return state.selectedRoom;
  },
  unreadMessage(state) {
    return state.unreadMessage;
  },
  unservedRoom(state) {
    return state.unservedRoom;
  },
  livecall(state) {
    return state.livecall;
  },
  getAdditionalInformation(state) {
    return state.selectedRoom?.additional_informations;
  },
  getSelectedTags(state) {
    return state.selectedRoom?.tags;
  },
  getNotes(state) {
    return state.selectedRoom?.notes;
  },
  customerInfo(state) {
    return state.customer_info;
  },
  getRoomLink(state) {
    return state.room_link;
  },
  showEmoji(state) {
    return state.show_emoji;
  },
  isListening(state) {
    return state.is_listening;
  },
  selectOnce(state) {
    return state.select_once;
  },
  getUnservedRoom(state, _, rootState) {
    let access_group = rootState.accessibility.access.groups;
    if (rootState.accessibility.isAgent) {
      return state.unservedRoom.filter((each_room) => {
        if (access_group.length > 0) {
          if (each_room.livechat.group_id == null) {
            return false;
          } else {
            if (
              some(access_group, function (o) {
                return o.group_id == each_room.livechat.group_id;
              })
            ) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          if (each_room.livechat.group_id == null) {
            return true;
          } else {
            return false;
          }
        }
      });
    } else {
      return state.unservedRoom;
    }
  },
  selectedChannels(state) {
    return state.selected_channels;
  },
  selectedBusinessUnit(state) {
    return state.selected_business_unit;
  },
  selectedTicket(state) {
    return state.selected_ticket;
  },
  selectedTags(state) {
    return state.selected_tags;
  },
  countRoomByStatus(state) {
    return state.countRoomByStatus;
  },
  getSessionOnResolved(state) {
    return state.session;
  },
  getBroadcastList(state) {
    return state.broadcastList;
  },
  requestLivecall(state) {
    return state.request_livecall;
  },
  countChat(state) {
    return state.count_chat_by_status;
  },
  isAgent(state) {
    return state.isAgent;
  },
  getFreshchatList(state) {
    return state.freshChat;
  },
  getAgentList(state) {
    return state.agent_list;
  },
  scrolling: (state) => state.scrolling,
};

const mutations = {
  HANDLE_SENT_MESSAGE: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.roomId;
    });
    state.roomList[roomIndex].messages.push(payload.message);
  },
  UPDATE_MESSAGE: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.message.room_id;
    });
    let messageIndex = findIndex(
      state.roomList[roomIndex].messages,
      function (obj) {
        return obj.temporary_id == payload.temporaryId;
      }
    );
    state.roomList[roomIndex].messages[messageIndex].created_at =
      payload.message.created_at;
    state.roomList[roomIndex].messages[messageIndex] = payload.message;
    state.roomList[roomIndex].updated_at = payload.message.created_at;
  },
  RESET_SELECTED_ROOM: (state) => {
    state.selectedRoom = null;
  },
  SET_UNREAD_MESSAGE: (state, payload) => {
    state.unreadMessage = payload;
  },
  FILTER_ROOM_CHAT: (state, payload) => {
    let channel = payload.channel ? payload.channel.name : null;
    let tags = payload.tags ? payload.tags : null;
    let business_unit_id = payload.integration
      ? payload.integration.business_unit_id
      : null;
    let keyword = [
      payload.created_by.name,
      payload.created_by.phone,
      payload.created_by.email,
    ];

    let filter = {
      status: false,
      is_channel: false,
      is_business_unit: false,
      is_tags: false,
      is_ticket: false,
      is_keyword: false,
      channel: false,
      business_unit: false,
      tags: false,
      ticket: false,
      keyword: false,
    };

    if (
      state.selected_channels.length > 0 ||
      state.selected_business_unit.length > 0 ||
      state.selected_tags.length > 0 ||
      state.selected_ticket.length > 0 ||
      state.search_keyword != ""
    ) {
      filter.status = true;
    }

    if (state.selected_channels.length > 0) {
      filter.is_channel = true;
      let channelCheck = state.selected_channels.find(
        (item) => item === channel
      );
      if (channelCheck == channel) {
        filter.channel = true;
      }
    }

    if (state.selected_business_unit.length > 0) {
      filter.is_business_unit = true;
      let buCheck = state.selected_business_unit.find(
        (item) => item === business_unit_id
      );
      if (buCheck == business_unit_id) {
        filter.business_unit = true;
      }
    }
    if (state.selected_tags.length > 0) {
      filter.is_tags = true;
      for (let i = 0; i < state.selected_tags.length; i++) {
        let tagsCheck = tags.find(
          (item) => item.name === state.selected_tags[i]
        );
        if (tagsCheck && tagsCheck.name == state.selected_tags[i]) {
          filter.tags = true;
        }
      }
    }

    if (state.selected_ticket.length > 0) {
      filter.ticket = state.selected_ticket;
    }
    // console.log("ticket filter ", filter);
    if (state.search_keyword != "") {
      filter.is_keyword = true;
      for (let k = 0; k < keyword.length; k++) {
        if (keyword[k].includes(state.search_keyword)) {
          filter.keyword = true;
        }
      }
    } else {
      filter.is_keyword = true;
      filter.keyword = true;
    }
    state.filterRoom = filter;
  },
  SET_SESSION: (state, payload) => {
    state.session = payload;
  },
  LIVE_CALL: (state, payload) => {
    state.livecall = payload;
  },
  SET_ROOM_LIST: (state, payload) => {
    // _.map(payload, function(val) {
    //   if (val.messages[0].encrypt) {
    //     return Encryptor.decryptMessage(val.messages[0].content);
    //   }
    //   return val;
    // });
    state.roomList = payload;
  },
  HANDLE_SELECT_ROOM: (state, payload) => {
    const data = find(state.roomList, function (obj) {
      return obj.id == payload.id;
    });

    if (data) {
      state.selectedRoom = data;
    }
  },
  HANDLE_NEW_MESSAGE: (state, payload) => {
    let isExist = find(state.roomList, function (obj) {
      return obj.id == payload.room.id;
    });
    if (isExist) {
      // is room exist
      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == payload.room.id;
      });
      state.roomList[roomIndex].messages.push(payload.message);
      state.roomList[roomIndex].updated_at = payload.room.updated_at;
      state.roomList[roomIndex].livechat = payload.room.livechat;
      return;
    } else {
      // if doesnt exist, add as new room

      // const agent = state.isAgent
      //   ? { agent_id: this.$store.getters.localStorage.decodedUserId }
      //   : {};

      // const response = RoomService.getCountRoomByStatus(agent);
      // state.countRoomByStatus = response.data;

      payload.room.unread_count = 0;
      let room = cloneDeep(payload.room);
      let integration = cloneDeep(payload.integration);
      assignIn(room.messages[0], {
        messageable: payload.message.messageable,
      });
      assignIn(room, {
        tags: [],
        tickets: [],
        notes: null,
      });
      state.roomList.push(room);

      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == payload.room.id;
      });

      if (state.roomList[roomIndex].integration) {
        state.roomList[roomIndex].integration.business_unit = integration
          ? integration.business_unit
          : null;
      }
    }
  },
  ADD_MORE_MESSAGES: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == state.selectedRoom.id;
    });
    state.roomList[roomIndex].messages = payload.concat(
      state.roomList[roomIndex].messages
    );
  },
  SET_MESSAGES_WHEN_SEARCH: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == state.selectedRoom.id;
    });
    state.roomList[roomIndex].messages = payload.messages;
  },
  ADD_MORE_ROOM_LIST: (state, payload) => {
    state.roomList = state.roomList.concat(payload);
  },
  REQUEST_LIVE_CHAT: (state, { room }) => {
    state.unservedRoom.push(room);
    // state.unservedRoom++;
    let isExist = find(state.roomList, function (obj) {
      return obj.id == room.id;
    });
    if (isExist) {
      // is room exist
      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == room.id;
      });
      state.roomList[roomIndex].livechat = room.livechat;
      return;
    } else {
      // if doesnt exist
      room.unread_count = 0;
      state.roomList.push(room);
    }
  },
  RESOLVED_LIVE_CHAT: (state, payload) => {
    let isExist = find(state.roomList, function (obj) {
      return obj.id == payload.room.id;
    });
    if (isExist) {
      // is room exist
      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == payload.room.id;
      });
      // reset null if resolved, else set with response data
      state.roomList[roomIndex].livechat = payload.room.livechat;
    } else {
      // state.roomList.unshift(payload.messages);
    }
  },
  UPDATE_LIVE_CHAT: (state, payload) => {
    let isExist = find(state.roomList, function (obj) {
      return obj.id == payload.room.id;
    });
    if (isExist) {
      // is room exist
      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == payload.room.id;
      });
      // reset null if resolved, else set with response data
      state.roomList[roomIndex].livechat =
        payload.liveChat.status == "resolved" ? null : payload.liveChat;
      return;
    } else {
      state.roomList = state.roomList.unshift(payload.messages);
    }
  },
  SET_UNREAD_COUNT: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room.id;
    });

    if (state.roomList[roomIndex]) {
      switch (payload.event) {
        case "select_room":
          state.roomList[roomIndex].unread_count = 0;
          break;
        case "new_message_selected":
          state.roomList[roomIndex].unread_count = 0;
          break;
        case "new_message":
          state.roomList[roomIndex].unread_count++;
          break;
        default:
          break;
      }
    }
  },
  NEW_TAG: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldTag = state.roomList[roomIndex].tags;
    let newTag = [...oldTag, payload];
    state.roomList[roomIndex].tags = newTag;
  },
  DELETE_TAG: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldTag = state.roomList[roomIndex].tags;
    let newTag = oldTag.filter((item) => item.id !== payload.tag_id);
    state.roomList[roomIndex].tags = newTag;
  },
  SET_UNSERVED_ROOM: (state, payload) => {
    state.unservedRoom = payload;
  },
  DELETE_INFORMATION: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldInformations = state.roomList[roomIndex].additional_informations;
    let newInformations = oldInformations.filter(
      (item) => item.id !== payload.id
    );
    state.roomList[roomIndex].additional_informations = newInformations;
  },
  NEW_INFORMATION: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldInformations = state.roomList[roomIndex].additional_informations;
    let newInformations = [...oldInformations, payload.data];
    state.roomList[roomIndex].additional_informations = newInformations;
  },
  UPDATE_INFORMATION: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldInformations = state.roomList[roomIndex].additional_informations;
    let informationIndex = findIndex(oldInformations, function (obj) {
      return obj.id == payload.data.id;
    });
    oldInformations[informationIndex] = payload.data;
    let newInformations = oldInformations;
    state.roomList[roomIndex].additional_informations = newInformations;
  },
  UPDATE_PHONE_AND_EMAIL: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    let oldData = state.roomList[roomIndex].created_by;
    state.roomList[roomIndex].created_by = {
      ...oldData,
      city: payload.city,
      phone: payload.phone,
    };
  },
  UPDATE_NOTES: (state, payload) => {
    let roomIndex = findIndex(state.roomList, function (obj) {
      return obj.id == payload.room_id;
    });
    state.roomList[roomIndex].notes = Object.assign({}, payload.data);
  },
  // REQUEST_HANDLED: (state, payload) => {
  //   const isExist = state.roomList.some((el) => el.id == payload.room.id);
  //   if (isExist) {
  //     let roomIndex = findIndex(state.roomList, function (obj) {
  //       return obj.id == payload.room.id;
  //     });
  //     state.roomList[roomIndex].livechat =
  //       payload.room.livechat.status == "resolved" ? null : payload.livechat;
  //     let currentRoom = state.roomList[roomIndex]; // get current room
  //     state.roomList.splice(roomIndex, 1); // change current room  top of array object
  //     state.roomList.unshift(currentRoom);
  //   } else {
  //     state.roomList.unshift({
  //       ...payload.room,
  //       livechat: payload.livechat,
  //     });
  //   }
  // },
  // ASSIGN_AGENT: (state, payload) => {
  //   const isExist = state.roomList.some((el) => el.id == payload.room.id);
  //   if (isExist) {
  //     let roomIndex = state.roomList.findIndex(
  //       (el) => el.id == payload.room.id
  //     );
  //     state.roomList[roomIndex].livechat = payload.room.livechat;
  //     state.roomList[roomIndex].isAssign = true;
  //   } else {
  //     state.roomList.unshift(payload.room);
  //   }
  // },
  SET_CURRENT_STATUS: (state, payload) => (state.current_status = payload),
  SET_BLOCK_OR_UNBLOCK_USER: (state, { current_status, room_id }) => {
    let room_index = state.roomList.findIndex(
      (each_room) => each_room.id == room_id
    );
    if (current_status == "block") {
      state.roomList[room_index].is_blocked = true;
    } else {
      state.roomList[room_index].is_blocked = false;
    }
  },
  LIVECHAT_ASSIGNED: (state, { room, is_agent, access_group }) => {
    if (room.livechat.group_id !== null) {
      if (
        is_agent &&
        access_group.groups.length > 0 &&
        room.livechat.handle_by == null
      ) {
        let group = find(access_group.groups, function (o) {
          return o.group_id == room.livechat.group_id;
        });
        if (group) {
          state.unservedRoom.push(room);
        }
      } else {
        const is_exist_room_list = state.roomList.some(
          (each_room) => each_room.id == room.id
        );
        if (is_exist_room_list) {
          const room_index_room_list = state.roomList.findIndex(
            (each_room) => each_room.id == room.id
          );
          state.roomList[room_index_room_list].livechat = room.livechat;
          state.roomList[room_index_room_list].tickets = room.tickets;
        } else {
          state.roomList.push(room);
        }
      }
    } else {
      const is_exist_room_list = state.roomList.some(
        (each_room) => each_room.id == room.id
      );
      if (is_exist_room_list) {
        const room_index_room_list = state.roomList.findIndex(
          (each_room) => each_room.id == room.id
        );
        state.roomList[room_index_room_list].livechat = room.livechat;
        state.roomList[room_index_room_list].tickets = room.tickets;
      } else {
        state.roomList.push(room);
      }
    }
  },
  LIVECHAT_HANDLE: (state, { room, unserved_room }) => {
    const is_exist_room_list = state.roomList.some(
      (each_room) => each_room.id == room.id
    );
    if (is_exist_room_list) {
      const room_index = state.roomList.findIndex(
        (each_room) => each_room.id == room.id
      );
      state.roomList[room_index].livechat = room.livechat;
    } else {
      state.roomList.push(room);
    }
    state.unservedRoom = unserved_room;
  },
  SET_SELECTED_CHANNELS: (state, payload) => {
    state.selected_channels = payload;
  },
  GET_ROOM_LINK: (state, payload) => {
    state.room_link = payload;
  },
  SET_ROOM_LINK: (state, payload) => {
    state.room_link = payload;
  },
  SET_SELECTED_BUSINESS_UNIT: (state, payload) => {
    state.selected_business_unit = payload;
  },
  SET_SELECTED_TICKET: (state, payload) => {
    state.selected_ticket = payload;
  },
  SET_SELECTED_TAGS: (state, payload) => {
    state.selected_tags = payload;
  },
  SET_COUNTER_CHAT: (state, payload) => {
    state.count_chat_by_status = payload;
  },
  SET_SEARCH_KEYWORD: (state, payload) => {
    state.search_keyword = payload;
  },
  CUSTOMER_INFO: (state, payload) => {
    state.customer_info = payload;
  },
  REQUEST_LIVECALL: (state, payload) => {
    state.request_livecall = payload;
  },
  SET_CONVERSATION_LABEL: (state, payload) => {
    if (state.selectedRoom.session) {
      state.selectedRoom.session.conversation_label = payload;
    }
  },
  SET_IS_AGENT: (state, payload) => {
    state.isAgent = payload;
  },
  LIVECHAT_CANCEL: (state, { room }) => {
    state.unservedRoom = state.unservedRoom.filter(
      (each_room) => each_room.id !== room.id
    );
    let is_exist = state.roomList.some((each_room) => each_room.id == room.id);
    if (is_exist) {
      let room_index = state.roomList.findIndex(
        (each_room) => each_room.id == room.id
      );
      state.roomList[room_index].livechat = room.livechat;
    }
  },
  SET_COUNT_STATUS_ROOM: (state, payload) => {
    state.countRoomByStatus = payload;
  },
  SET_SCROLLING: (state, payload) => {
    state.scrolling = payload;
  },
  REMOVE_CURRENT_ROOM: (state, payload) => {
    if (state.roomList.length > 0) {
      let is_exist = state.roomList.some(
        (each_room) => each_room.id == payload.room_id
      );
      if (is_exist) {
        remove(state.roomList, function (o) {
          return o.id == payload.id;
        });
      }
    }
  },
  SET_TICKET_ROOM: (state, payload) => {
    console.log("payload ticket ", payload.tickets);
    if (state.roomList.length > 0) {
      let is_exist = state.roomList.some(
        (each_room) => each_room.id == payload.room_id
      );
      if (is_exist) {
        let room_index = state.roomList.findIndex(
          (each_room) => each_room.id == payload.room_id
        );
        state.roomList[room_index].tickets = payload.tickets
          ? payload.tickets
          : [];
      }
    }
  },
  ADD_TICKET_ROOM: (state, payload) => {
    if (state.roomList.length > 0) {
      let is_exist = state.roomList.some(
        (each_room) => each_room.id == payload.room_id
      );
      if (is_exist) {
        let room_index = state.roomList.findIndex(
          (each_room) => each_room.id == payload.room_id
        );
        state.roomList[room_index].tickets.push(payload.ticket);
      }
    }
  },
  REMOVE_TICKET_ROOM: (state, payload) => {
    if (state.roomList.length > 0) {
      let is_exist = state.roomList.some(
        (each_room) => each_room.id == payload.room_id
      );
      if (is_exist) {
        let room_index = state.roomList.findIndex(
          (each_room) => each_room.id == payload.room_id
        );
        state.roomList[room_index].tickets = [];
      }
    }
  },
  HANDLE_CHANGE_MESSAGE: (state, payload) => {
    let isExist = find(state.roomList, function (obj) {
      return obj.id == payload.room.id;
    });
    if (isExist) {
      // is room exist
      let roomIndex = findIndex(state.roomList, function (obj) {
        return obj.id == payload.room.id;
      });
      let messageIndex = findIndex(
        state.roomList[roomIndex].messages,
        function (obj) {
          return obj.id == payload.message.id;
        }
      );
      if (messageIndex) {
        state.roomList[roomIndex].messages[messageIndex] = payload.message;
      }
      return;
    }
  },
  SET_BROADCAST_LIST: (state, payload) => {
    state.broadcastList = payload;
  },
  SET_FRESHCHAT_LIST: (state, payload) => {
    state.freshChat = payload;
  },
  SET_SHOW_EMOJI: (state, payload) => {
    state.show_emoji = payload;
  },
  SET_IS_LISTENING: (state, payload) => {
    state.is_listening = payload;
  },
  SET_AGENT_LIST: (state, payload) => {
    state.agent_list = payload;
  },
  SELECT_ONCE: (state, payload) => {
    state.select_once = payload;
  },
};

const actions = {
  selectOnce(context, payload) {
    context.commit("SELECT_ONCE", payload);
  },
  setLivechatTicket(context, payload) {
    try {
      const { data: response } = RoomService.setLivechatTicket(payload);
    } catch (error) {
      Toast.error(error);
    }
  },
  async getAgentList(context, payload) {
    try {
      const { data: response } = await AgentService.getMember(payload);
      context.commit("SET_AGENT_LIST", response.data);
    } catch (error) {
      const e = error.response ? error.response.data.message : error;
      Toast.error(e);
    }
  },
  showEmoji(context, payload) {
    context.commit("SET_SHOW_EMOJI", payload);
  },
  isListening(context, payload) {
    context.commit("SET_IS_LISTENING", payload);
  },
  async filterRoom(context, payload) {
    // UPDATE COUNT CHAT
    // let isnCount = payload.isn_count;
    // if (!isnCount) {
    //   const agent = payload.agent
    //     ? { agent_id: this.$store.getters.localStorage.decodedUserId }
    //     : {};

    //   const response = await RoomService.getCountRoomByStatus(agent);
    //   context.commit("SET_COUNT_STATUS_ROOM", response.data);
    // }

    context.commit("FILTER_ROOM_CHAT", payload.room);
    let filter = state.filterRoom;
    var result = true;
    if (filter.status) {
      if (filter.is_channel) {
        if (filter.is_channel != filter.channel) result = false;
      }

      if (filter.is_business_unit) {
        if (filter.is_business_unit != filter.business_unit) result = false;
      }

      if (filter.is_tags) {
        if (filter.is_tags != filter.tags) result = false;
      }

      if (filter.is_keyword) {
        if (filter.is_keyword != filter.keyword) result = false;
      }
    }
    state.filterRoom = result;
  },
  liveChatResolved(context, payload) {
    context.commit("RESOLVED_LIVE_CHAT", payload);
  },
  liveChatRequest(context, payload) {
    context.commit("REQUEST_LIVE_CHAT", payload);
  },
  liveChatUpdate(context, payload) {
    context.commit("UPDATE_LIVE_CHAT", payload);
  },
  newMessage(context, payload) {
    if (!payload.room) {
      return;
    }

    context.commit("HANDLE_NEW_MESSAGE", payload);
    if (
      !isNull(context.getters.selectedRoom) &&
      context.getters.selectedRoom.id == payload.room.id
    ) {
      context.dispatch("updateUnreadMessage", {
        room: payload.room,
        user: payload.user,
        senderId: context.rootGetters.localStorage.decodedUserId,
        integration: payload.integration,
        forceUpdate: true,
      });
      context.commit("SET_UNREAD_COUNT", {
        ...payload,
        event: "new_message_selected",
      });
    } else {
      context.commit("SET_UNREAD_COUNT", { ...payload, event: "new_message" });
    }
  },
  async updateUnreadMessage(context, payload) {
    if (!payload.room) {
      return;
    }

    let condition = !isUndefined(payload.room.unread_count)
      ? payload.room.unread_count > 0
      : payload.forceUpdate == true;
    if (condition) {
      ConversationService.updateUnreadMessage({
        roomId: payload.room.id,
        senderId: payload.senderId,
      });
    }
  },
  async selectRoom({ commit, dispatch }, payload) {
    dispatch("updateUnreadMessage", payload);
    commit("HANDLE_SELECT_ROOM", payload.room);
    commit("SET_UNREAD_COUNT", { ...payload, event: "select_room" });
  },
  async sendMessage(context, payload) {
    let lennaMessageType = new LennaMessageType({
      messageable_id: payload.senderId,
      roomId: payload.room.id,
    });

    var message;
    switch (payload.message.type) {
      case "text":
        console.log("text");
        message = lennaMessageType.text({
          text: payload.message.text,
        });
        break;
      case "image":
        message = lennaMessageType.image({
          fileName: payload.message.fileName,
          fileUrl: payload.message.fileUrl,
        });
        break;
      case "file":
        message = lennaMessageType.file({
          fileName: payload.message.fileName,
          fileUrl: payload.message.fileUrl,
        });
        break;
      case "template":
        message = lennaMessageType.template({
          template: payload.message.template,
        });
        break;
      case "private-note":
        message = lennaMessageType.privateNote({
          text: payload.message.text,
        });
        break;
      case "private-note-image":
        message = lennaMessageType.privateNoteImage({
          fileName: payload.message.fileName,
          fileUrl: payload.message.fileUrl,
        });
        break;
      case "private-note-file":
        message = lennaMessageType.privateNoteFile({
          fileName: payload.message.fileName,
          fileUrl: payload.message.fileUrl,
        });
        break;
      default:
        break;
    }
    // add is_visible key
    console.log("messages ", message);
    assignIn(message, { is_visible: true });
    context.commit("HANDLE_SENT_MESSAGE", {
      roomId: payload.room.id,
      message,
    });
    try {
      let response;
      if (payload.message.type == "private-note") {
        response = await ConversationService.setPrivateNote({
          roomId: payload.room.id,
          text: payload.message.text,
        });
      } else if (
        payload.message.type == "private-note-image" ||
        payload.message.type == "private-note-file"
      ) {
        response = await ConversationService.setPrivateNoteImage({
          roomId: payload.room.id,
          senderId: payload.senderId,
          message: message.content,
          temporaryId: message.temporary_id,
        });
      } else {
        response = await ConversationService.sendMessage({
          roomId: payload.room.id,
          senderId: payload.senderId,
          message: message.content,
          temporaryId: message.temporary_id,
        });
      }
      context.commit("UPDATE_MESSAGE", response.data.data);
    } catch (error) {
      const e = error.response ? error.response.data.message : error;
      Toast.error(e);
    }
  },
  async getRoomList({ rootState }, payload = {}) {
    // if (payload.isInitial) {
    //   commit("SET_ROOM_LIST", []);
    // }
    // const isAgent = await rootGetters["accessibility/isAgent"];
    // if (isAgent) {
    //   commit("SET_CURRENT_STATUS", "live");
    // }
    // const channel = rootState.chat.selected_channels;
    Object.assign(payload, {
      status: rootState.chat.current_status,
      channel: rootState.chat.selected_channels,
      business_unit: rootState.chat.selected_business_unit,
      tags: rootState.chat.selected_tags,
      ticket: rootState.chat.selected_ticket,
    });
    const { data: response } = await RoomService.getRoomListV3(payload);
    // commit("SET_ROOM_LIST", response.data);
    return response.data;
  },
  handleInfiniteScroll(context, payload) {
    context.commit("ADD_MORE_MESSAGES", payload.messages);
  },
  handleSetMessageWhenSearchMessage(context, payload) {
    context.commit("SET_MESSAGES_WHEN_SEARCH", payload);
  },
  getMoreRoom(context, payload) {
    context.commit("ADD_MORE_ROOM_LIST", payload.rooms);
  },
  updateRoomList(context, payload) {
    context.commit("SET_ROOM_LIST", payload);
  },
  async countChat(context, payload) {
    const { data: response } = await RoomService.chatCount(payload);
    context.commit("SET_COUNTER_CHAT", response.data);
  },
  newTag(context, payload) {
    context.commit("NEW_TAG", payload);
  },
  removeTag(context, payload) {
    context.commit("DELETE_TAG", payload);
  },
  async fetchUnservedRoom(context) {
    const { data: response } = await RoomService.fetchUnservedRoom();
    context.commit("SET_UNSERVED_ROOM", response.data);
  },
  newInformation(context, payload) {
    context.commit("NEW_INFORMATION", payload);
  },
  removeInformation(context, payload) {
    context.commit("DELETE_INFORMATION", payload);
  },
  updateInformation(context, payload) {
    context.commit("UPDATE_INFORMATION", payload);
  },
  updateUserInformation(context, payload) {
    context.commit("UPDATE_USER_INFORMATION", payload);
  },
  async getRoomLink(context, payload) {
    try {
      const response = await RoomService.getRoomLink(payload);

      context.commit("GET_ROOM_LINK", response);
    } catch (error) {
      Toast.error(error);
    }
  },
  setRoomLink(context, payload) {
    context.commit("SET_ROOM_LINK", payload);
  },
  updateNotes({ commit }, payload) {
    commit("UPDATE_NOTES", payload);
  },
  customerInfo({ commit }, payload) {
    commit("CUSTOMER_INFO", payload);
  },
  // requestHandled({ commit }, payload) {
  //   commit("REQUEST_HANDLED", payload);
  // },
  // assignToAgent({ commit }, payload) {
  //   commit("ASSIGN_AGENT", payload);
  // },
  resetSelectedRoom({ commit }) {
    commit("RESET_SELECTED_ROOM");
  },
  async sendMessageToTriggerNps(
    { rootGetters },
    { channel, created_by, room_id }
  ) {
    let message;
    let url;
    switch (channel) {
      case "telegram":
        message = {
          update_id: Math.floor(Date.now() / 1000),
          message: {
            message_id: 1000,
            from: {
              id: created_by.channel_user_id,
              is_bot: false,
            },
            chat: {
              id: created_by.channel_user_id,
              type: "private",
            },
            date: Math.floor(Date.now() / 1000),
            text:
              rootGetters["app/app"].keyword_for_trigger_rating || "show nps",
          },
        };
        url = "webhook/telegram";
        break;
      case "webchat":
        message = {
          events: "message",
          senderId: created_by.id,
          message: {
            messageable_id: created_by.id,
            temporary_id: Math.floor(Date.now() / 1000),
            created_at: null,
            messageable_type: "user",
            id: Math.floor(Date.now() / 1000),
            content: [
              {
                type: "text",
                text:
                  rootGetters["app/app"].keyword_for_trigger_rating ||
                  "show nps",
                speech:
                  rootGetters["app/app"].keyword_for_trigger_rating ||
                  "show nps",
              },
            ],
          },
        };
        url = "webhook/webchat";
        break;
      case "whatsapp":
        // check 24 hou
        let checkWa20hours = await this.$axios.post(
          `api/${rootGetters.appId.hashed}/chat/wa-24-hour-check`,
          {
            room_id,
          }
        );
        if (
          checkWa20hours.data.message == "this user may not receive the message"
        ) {
          return Toast.success(response.data.message);
        }
        let response = await this.$axios.get(
          `/api/${rootGetters.appId.hashed}/integrate`
        );
        let currentIntegration = response.data.data.find(
          (each_integration) => each_integration.name == "whatsapp"
        );
        url = `webhook/whatsapp${currentIntegration.integration.integration_data.apiService}`;
        message = {
          contacts: [
            {
              wa_id: created_by.channel_user_id,
            },
          ],
          messages: [
            {
              from: created_by.channel_user_id,
              text: {
                body:
                  rootGetters["app/app"].keyword_for_trigger_rating ||
                  "show nps",
              },
              type: "text",
            },
          ],
        };
        break;
      case "mobile":
        let hashids = new Hashids("d161t4lSTEREO", 6);
        const userId = hashids.encode(created_by.id);
        message = {
          user_id: userId,
          query:
            rootGetters["app/app"].keyword_for_trigger_rating || "show nps",
          lat: "132",
          lon: "132",
          channel: "android",
        };
        url = "webhook/mobile";
        break;
      default:
        break;
    }
    const { data: response } = await this.$axios.post(
      `/api/${rootGetters.appId.hashed}/${url}`,
      message
    );
    if (channel == "mobile") {
      const payload = {
        content: response.result.output,
        created_by: created_by,
        channel: channel,
      };
      this.$axios.post(
        `/api/${rootGetters.appId.hashed}/broadcast-only/mobile`,
        payload
      );
    }
  },
  updatePhoneAndEmail({ commit }, payload) {
    commit("UPDATE_PHONE_AND_EMAIL", payload);
  },
  setCurrentStatus({ commit }, payload) {
    commit("SET_CURRENT_STATUS", payload);
  },
  setBlockOrUnblockUser({ commit }, payload) {
    commit("SET_BLOCK_OR_UNBLOCK_USER", payload);
  },
  livechatAssigned({ commit, rootState }, payload) {
    commit("LIVECHAT_ASSIGNED", {
      ...payload,
      is_agent: rootState.accessibility.isAgent,
      access_group: rootState.accessibility.access,
    });
  },
  livechatHandle({ commit }, payload) {
    commit("LIVECHAT_HANDLE", payload);
  },
  setSelectedChannels({ commit }, payload) {
    commit("SET_SELECTED_CHANNELS", payload);
  },
  setSelectedBusinessUnit({ commit }, payload) {
    commit("SET_SELECTED_BUSINESS_UNIT", payload);
  },
  setSelectedTicket({ commit }, payload) {
    commit("SET_SELECTED_TICKET", payload);
  },
  setSelectedTags({ commit }, payload) {
    console.log("NNO TAG ", payload);
    commit("SET_SELECTED_TAGS", payload);
  },
  setSearchKeyword({ commit }, payload) {
    commit("SET_SEARCH_KEYWORD", payload);
  },
  livechatCancel({ commit }, payload) {
    commit("LIVECHAT_CANCEL", payload);
  },
  setFilterChannel({ commit }, payload) {
    commit("SET_FILTER_CHANNEL", payload);
  },
  requestLivecall({ commit }, payload) {
    commit("REQUEST_LIVECALL", payload);
  },
  setConversationLabel({ commit }, payload) {
    commit("SET_CONVERSATION_LABEL", payload);
    RoomService.setLabelToSession(payload);
  },
  async getCountRoomByStatus({ commit }, payload) {
    try {
      const response = await RoomService.getCountRoomByStatus(payload);
      commit("SET_COUNT_STATUS_ROOM", response.data);
      return response.data;
    } catch (error) {
      Toast.error(error);
    }
  },
  async getCustomer(context, payload) {
    try {
      const agent = state.isAgent
        ? { agent_id: this.$store.getters.localStorage.decodedUserId }
        : {};
      const countRoom = await RoomService.getCountRoomByStatus(agent);
      context.commit("SET_COUNT_STATUS_ROOM", countRoom.data);

      const response = await RoomService.getCustomer(payload);
      return response.data;
    } catch (error) {
      Toast.error(error.response.data.message);
    }
  },
  async getMoreMessage(context, payload) {
    try {
      const response = await ConversationService.getMoreMessages(payload);
      return response.data;
    } catch (error) {
      Toast.error(error);
    }
  },
  removeCurrentRoom({ commit }, payload) {
    commit("REMOVE_CURRENT_ROOM", payload);
  },
  async searchMessage(context, payload) {
    try {
      const response = await ConversationService.searchMessage(payload);
      return response.data;
    } catch (error) {
      Toast.error(error);
    }
  },
  async updateTicketRoom({ commit }, payload) {
    commit("SET_TICKET_ROOM", payload);
  },
  async addTicketRoom({ commit }, payload) {
    commit("ADD_TICKET_ROOM", payload);
  },
  async removeTicketsRoom({ commit }, payload) {
    commit("REMOVE_TICKET_ROOM", payload);
  },
  async checkWa24Hours(context, payload) {
    const response = await ConversationService.checkWa24Hours({
      room_id: payload,
    });
    return response;
  },
  getSessionOnResolved(context, payload) {
    context.commit("SET_SESSION", payload);
  },
  changeMessage({ commit }, payload) {
    commit("HANDLE_CHANGE_MESSAGE", payload);
  },
  async getBroadcast(context, payload) {
    let response = await httpClientOmni.get(
      `/user/get-broadcast-message/${payload}`
    );
    context.commit("SET_BROADCAST_LIST", response.data);
  },
  async livecall(context, payload) {
    try {
      const { data: response } = await LivechatService.livecall(payload);
      context.commit("LIVE_CALL", response);
    } catch (error) {
      Toast.error(error.response);
    }
  },
  isAgent(context, payload) {
    context.commit("SET_IS_AGENT", payload);
  },
  async getFreshchat(context, payload) {
    let response = await httpClientOmni.post(`/freshchat/conversations`, {
      number: payload,
    });
    context.commit("SET_FRESHCHAT_LIST", response.data);
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
