import { httpClientOmni } from "@/libraries";

const state = {
    whatsappFlows: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    filterText: '',
    createModalVisible: false,
    createLoading: false,
    currentFlow: null,
    editLoading: false,
    integrations: []
  }
  
  const mutations = {
    SET_WHATSAPP_FLOWS(state, data) {
      state.whatsappFlows = data.data
      state.pagination.total = data.total
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = { ...state.pagination, ...pagination }
    },
    SET_FILTER_TEXT(state, text) {
      state.filterText = text
    },
    SET_CREATE_MODAL_VISIBLE(state, visible) {
      state.createModalVisible = visible
    },
    SET_CREATE_LOADING(state, loading) {
      state.createLoading = loading
    },
    SET_CURRENT_FLOW(state, flow) {
      state.currentFlow = flow
    },
    SET_EDIT_LOADING(state, loading) {
      state.editLoading = loading
    },
    SET_INTEGRATIONS(state, integrations) {
      state.integrations = integrations
    }
  }
  
  const actions = {
    // OLD
    /*
    async fetchWhatsappFlows({ commit, state }) {
      try {
        commit('SET_LOADING', true)
        const response = await httpClientOmni.get('v3/whatsapp-flows/datatable', {
          params: {
            page: state.pagination.current,
            per_page: state.pagination.pageSize,
            sort: 'id|desc',
            filterText: state.filterText
          }
        })
        commit('SET_WHATSAPP_FLOWS', response.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    */

    async fetchWhatsappFlows({ commit, state }, options = {}) {
      try {

        // const { commit, state } = context;  // Ambil commit dan state dari context
        
        const { filters = {}, sort = null } = options; // Use destructuring with default values
        commit('SET_LOADING', true);

        const response = await httpClientOmni.get('v3/whatsapp-flows/datatable', {
          params: {
            page: state.pagination.current,
            per_page: state.pagination.pageSize,
            sort: sort,
            filterText: state.filterText,
            ...filters, // Spread filters to include additional options
          },
        });

        commit('SET_WHATSAPP_FLOWS', response.data);
      } catch (error) {
        console.error('Failed to fetch flows:', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },


  
    async fetchIntegrations({ commit }) {
      try {
        const response = await httpClientOmni.get('v3/master/integration-by-app-id')
        if (response.data && Array.isArray(response.data.data)) {
          commit('SET_INTEGRATIONS', response.data.data)
        }
      } catch (error) {
        console.error('Failed to fetch integrations:', error)
      }
    },
  
    async fetchFlowDetail({ commit }, id) {
      try {
        commit('SET_EDIT_LOADING', true)
        const response = await httpClientOmni.get(`v3/whatsapp-flows/${id}/detail`)
        commit('SET_CURRENT_FLOW', response.data.data)
        return { success: true, data: response.data.data }
      } catch (error) {
        console.error(error)
        return { success: false, error: error.response?.data?.message || 'An error occurred' }
      } finally {
        commit('SET_EDIT_LOADING', false)
      }
    }
  }
  
  const getters = {
    integrationOptions: state => state.integrations
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }