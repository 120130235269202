import { ReportAllMessageService } from "@/services";
import { httpClientOmni } from "@/libraries";


const state = {
    data: [],
    totalMessageBot: 0,
    totalMessageAssistant: 0,
    totalMessageAgent: 0,
    totalMessageUser: 0,
    loading: false,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0
    },
    search: '',
    masterTags: [],
    masterChannel: [],
    masterMessageType: [],
    masterSenderType: [],
}

const mutations = {
    SET_DATATABLE(state, data) {
        state.data = data.data
        state.pagination.total = data.total

        state.totalMessageBot = data.total_message_bot
        state.totalMessageAssistant = data.total_message_assistant
        state.totalMessageAgent = data.total_message_user_platform
        state.totalMessageUser = data.total_message_user
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = { ...state.pagination, ...pagination }
    },
    SET_FILTER_TEXT(state, text) {
        state.search = text
    },
    SET_CREATE_MODAL_VISIBLE(state, visible) {
        state.createModalVisible = visible
    },
    SET_CREATE_LOADING(state, loading) {
        state.createLoading = loading
    },
    SET_CURRENT_FLOW(state, flow) {
        state.currentFlow = flow
    },
    SET_EDIT_LOADING(state, loading) {
        state.editLoading = loading
    },
    SET_TAGS(state, tags) {
        state.masterTags = tags
    },
    SET_MESSAGE_TYPE(state, messageType) {
        state.masterMessageType = messageType
    },
    SET_SENDER_TYPE(state, senderType) {
        state.masterSenderType = senderType
    },
    SET_CHANNEL(state, channel) {
        state.masterChannel = channel
    }
}

const actions = {
    // OLD
    /*
    async fetchDatatable({ commit, state }) {
      try {
        commit('SET_LOADING', true)
        const response = await httpClientOmni.get('v3/whatsapp-flows/datatable', {
          params: {
            page: state.pagination.current,
            per_page: state.pagination.pageSize,
            sort: 'id|desc',
            search: state.search
          }
        })
        commit('SET_DATATABLE', response.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    */

    async fetchDatatable({ commit, state }, options = {}) {
        try {

            // const { commit, state } = context;  // Ambil commit dan state dari context

            const { filters = {}, sort = null } = options; // Use destructuring with default values
            commit('SET_LOADING', true);

            const response = await ReportAllMessageService.datatable({
                page: state.pagination.current,
                per_page: state.pagination.pageSize,
                sort: sort,
                search: state.search,
                ...filters, // Spread filters to include additional options
            });

            commit('SET_DATATABLE', response.data);
        } catch (error) {
            console.error('Failed to fetch flows:', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },


    async fetchMessageType({ commit }) {
        try {
            const response = await httpClientOmni.get('v3/master/message-type')
            if (response.data && Array.isArray(response.data.data)) {
                commit('SET_MESSAGE_TYPE', response.data.data)
            }
        } catch (error) {
            console.error('Failed to fetch tags:', error)
        }
    },

    async fetchSenderType({ commit }) {
        try {
            const response = await httpClientOmni.get('v3/master/sender-type')
            if (response.data && Array.isArray(response.data.data)) {
                commit('SET_SENDER_TYPE', response.data.data)
            }
        } catch (error) {
            console.error('Failed to fetch tags:', error)
        }
    },

    async fetchChannel({ commit }) {
        try {
            const response = await httpClientOmni.get('v3/master/channel')
            if (response.data && Array.isArray(response.data.data)) {
                commit('SET_CHANNEL', response.data.data)
            }
        } catch (error) {
            console.error('Failed to fetch channel:', error)
        }
    },

}

const getters = {
    msgTypeOptions: state => state.masterMessageType,
    senderTypeOptions: state => state.masterSenderType,
    channelOptions: state => state.masterChannel,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}