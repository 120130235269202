<template>
  <b-modal
    v-model="modalShow"
    :title="title"
    hide-footer
    modal-class="flip"
    class="v-modal-custom"
    @hide="handleCloseModal"
    @hidden="handleCloseModal"
    centered
  >
    <form action="javascript:void(0);">
      <b-row class="g-3">
        <!-- form start -->
        <b-col xxl="12">
          <div>
            <label for="IntegrationName" class="form-label">
              Integration Name
              <span class="text-danger">*</span>
            </label>

            <input
              v-model="form.integration_name"
              type="text"
              class="form-control"
              id="IntegrationName"
              placeholder="Integration Name"
              required
            />
          </div>
        </b-col>

        <b-col xxl="6">
          <div>
            <label for="Email" class="form-label">
              Email
              <span class="text-danger">*</span>
            </label>

            <input
              v-model="form.email"
              type="text"
              class="form-control"
              id="Email"
              placeholder="Email address"
              :disabled="data ? true : false"
              required
            />
          </div>
        </b-col>

        <b-col xxl="6">
          <div>
            <label for="SenderName" class="form-label">
              Sender Name
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              id="SenderName"
              placeholder="Name"
              required
            />
          </div>
        </b-col>
        <!-- business unit -->
        <div class="col-lg-12 mt-3">
          <div>
            <label class="form-label">Business Unit</label>
            <treeselect
              label="label"
              name="label"
              class="bg-white"
              :options="businessUnit"
              :placeholder="business_unit ? business_unit : 'Select category'"
              v-model="form.business_unit_id"
              @option:selected="changeValueBusinessUnit"
            />
          </div>
        </div>
        <div class="col-lg-12 mt-3">
          <div
            class="form-check form-check-outline form-check-secondary mb-2 font-poppins fs-12 fw-normal"
          >
            <input class="form-check-input" type="checkbox" id="formCheck14" />
            <p class="form-check-label" for="formCheck14">
              Auto Response Ticket
            </p>
          </div>
        </div>
        <!-- group -->
        <!-- form default (dont edit) end -->
        <!-- bottom start-->
        <b-col lg="12">
          <div class="hstack gap-2 justify-content-end">
            <b-button type="button" variant="light" @click="handleCloseModal">
              Cancel
            </b-button>
            <b-button
              type="button"
              variant="warning"
              class="px-4"
              :loading="isSetPrimary"
              :disabled="isSetPrimary"
              @click="toggleSetPrimary(data.id)"
              v-if="data && !isPrimary"
            >
              Set Primary
            </b-button>
            <b-button
              type="submit"
              variant="secondary"
              class="px-4"
              @click="handleSubmit"
              :loading="loading"
              :disabled="loading"
            >
              Save
            </b-button>
          </div>
        </b-col>
        <!-- bottom end-->
      </b-row>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash"; // use this if you want to edit data from vuex.
import Treeselect from "vue3-treeselect";

import { httpClientOmni } from "@/libraries";

export default {
  components: {
    Treeselect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "Modal Integration",
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalShow: this.showModal,
      isSetPrimary: false,
      isPrimary: false,
      form: {
        name: null,
        integration_name: null,
        email: null,
        note: null,
        domain: null,
        integration_data: {},
      },
      business_unit: null,
    };
  },
  computed: {
    // dont forget this only getting data from vuex, ==================================== ||
    // make sure you call action to update this data before its show in modal box ======= ||
    ...mapGetters({
      businessUnit: "ticket/getBranchOffice",
    }),
  },
  watch: {
    // ================================================================================== ||
    // SHOW MODAL FUNC ================================================================== ||
    // first func running aftar modalbox trigged ======================================== ||
    // Set the value of modalShow to newVal ============================================= ||
    // Deep clone the data object and assign it to form ================================= ||
    // Set branch_office, group, and bot properties based on form data ================== ||
    // Generate the webhook URL using omniBaseUrl and form.id =========================== ||
    showModal(newVal) {
      this.modalShow = newVal;
      console.log("is data ", this.data);
      if (this.data != null) {
        this.form = cloneDeep(this.data.integration_data);
        this.isPrimary = this.data.is_primary;
        this.business_unit = this.data.business_unit
          ? this.data.business_unit.branch_office
          : null;
      } else {
        this.form.integration_data = {};
        this.form.name = null;
        this.form.integration_name = null;
        this.form.email = null;
        this.form.note = null;
        this.form.domain = null;
      }
    },
  },
  methods: {
    async toggleSetPrimary(id) {
      try {
        this.isSetPrimary = true;
        let response = await httpClientOmni.post(`email/set-primary`, {
          id: id,
        });
        this.successToast(response.data.message);
        this.isSetPrimary = false;
        this.$emit("onSetPrimary");
      } catch (error) {
        this.isSetPrimary = false;
        this.errorToast(this, error);
      }
    },

    // ================================================================================== ||
    // handleCloseModal FUNC ============================================================ ||
    handleCloseModal() {
      this.$emit("onClose"); // Emit an event named "onClose" to notify parent components
      this.modalShow = false; // Set the value of modalShow to false, hiding the modal
    },
    // ================================================================================== ||
    // handleSubmit FUNC ================================================================ ||
    // The && operator short-circuits if the left operand is falsy, ===================== ||
    // so the $emit call is only executed if the validate method returns a truthy value = ||
    handleSubmit() {
      if (this.data) {
        this.form.id = this.data.id;
        this.form.signature_id = this.data.integration_data.id ?? null;
      }
      this.form.status = true;
      this.validate() && this.$emit("onSubmit", this.form);
    },

    // ================================================================================== ||
    // validate FUNC ==================================================================== ||
    validate() {
      const data = this.form || {};
      const check = !!(data.integration_name && data.email && data.name);
      if (!check) this.errorToast("Field is required");

      return check;
    },

    changeValueBotList(val) {
      console.log("bot:", val);
      this.form.bot_id = val.id;
      this.bot = val.name;
    },

    changeValueBusinessUnit(val) {
      this.form.business_unit_id = val.id;
      this.business_unit = val.label;
    },
  },
};
</script>
