import { httpClientOmni } from "@/libraries";

export default {

    async datatable(payload) {
        const response = await httpClientOmni.get(
            `reporting/v3/all-message`,
            {
                params: payload,
            },
        );
        return response;
    },

    async exportExcel(payload) {
        const response = await httpClientOmni.get(
            `reporting/v3/all-message/export`,
            {
                params: payload,
            },
            // {
            //     responseType: "blob",
            // }
        );
        return response;
    },
};
