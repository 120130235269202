<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integration with Facebook Messenger</h5>

    <div class="row row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <div class="col" v-for="(val, key) in integrationList" :key="key">
        <div class="card card-body" style="background: #f1f5f9">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              <!-- Edit button -->
              <span @click="toggleEdit(val)" class="to-pointer">
                <i class="ri-edit-line text-mute me-2"></i>
              </span>
              <!-- end of Edit button -->

              <!-- Delete button -->
              <span @click="handleDelete(val.id)" class="to-pointer">
                <i class="ri-delete-bin-6-line text-danger"></i>
              </span>
              <!-- end of Delete button -->
            </div>
          </div>
          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-messenger-fill fs-20"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ val.integration_data.pageName }}
              </h5>
            </div>
          </div>

          <span class="card-timestamp text-muted mb-0 fs-12">
            Added at {{ val.created_at }}
          </span>
          <span class="card-timestamp text-muted fs-12">
            Last updated at {{ val.updated_at }}
          </span>
          <br />
          <base-button
            :class="{
              'btn-soft-secondary': !val.status,
              'btn-soft-danger': val.status,
            }"
            class="btn btn-sm"
            @click="toggleIntegration(val)"
            >{{ val.status ? "Deactivate" : "Activate" }}</base-button
          >
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100" style="background: #f1f5f9">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label>Add New Facebook Integration</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <IntegrationModal
    :isOpen="modal.create"
    :formFb="form"
    @onClose="modal.create = false"
    @onAdd="fetchData"
  />
  <IntegrationModal
    :isOpen="modal.edit"
    :formFb="formEdit"
    :modalTitle="'Edit Facebook Integration'"
    @onClose="modal.edit = false"
    @onSubmit="editSubmit"
  />
</template>

<script>
import IntegrationModal from "@/views/integration/components/facebook/ConfigFacebookModal.vue";
import { debounce } from "lodash";

export default {
  components: {
    IntegrationModal,
  },
  data() {
    return {
      selectedIntegration: null,
      modal: {
        create: false,
        edit: false,
        delete: false,
        loading: false,
      },
      formEdit: {},
      form: {
        userAccessToken: null,
        pageList: [],
        pageAccessToken: null,
        pageId: null,
        status: false,
        page: null,
        accountName: null,
        accountId: null,
        pageName: null,
        channelName: "facebook",
        bot_id: null,
        business_unit_id: null,
        group_id: null,
      },
      integrationId: null,
      pageLoading: false,
      business_unit: null,
      bot: null,
      group: null,
      integrationList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async fetchData() {
      try {
        const response = await this.$axios.get(
          `api/${this.$store.getters.appId.hashed}/integrate`
        );
        let channelData = response.data.data.find(function (item) {
          return item.name == "facebook";
        }).integration;
        this.integrationList = channelData;
        // this.successToast(response.data.message);
      } catch (error) {
        this.errorToast(error);
      }
    },
    async submitForm(data) {
      try {
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/integrate`,
          data
        );
        this.form.status = true;
        this.modal.create = false;
        await this.fetchData();
        // this.emitter.emit("hideIntegrationModal");
        this.successToast(response.data.message);
      } catch (error) {
        this.modal.create = false;
        this.errorToast(error);
      }
    },
    async toggleIntegration(data) {
      let param = {
        integrationId: data.id,
        status: data.status,
      };
      this.submitToggle(param, this);
    },
    toggleDelete(id) {
      this.selectedIntegration = id;
      this.modal.delete = true;
    },
    async handleDelete(id) {
      this.selectedIntegration = id;
      this.$swal
        .fire({
          title: "Are you sure, you want to delete this facebook integration?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, submit it!",
        })
        .then(async (result) => {
          this.$store.dispatch("loading/updateIsLoading", true);
          if (result.isConfirmed) {
            try {
              await this.$axios.delete(
                `api/${this.$store.getters.appId.hashed}/v3/remove-integration`,
                {
                  params: {
                    integrationId: this.selectedIntegration,
                  },
                }
              );
              this.successToast("Channel deleted");
              await this.fetchData();
              this.$store.dispatch("loading/updateIsLoading", false);
              this.modal.delete = false;
            } catch (error) {
              this.$store.dispatch("loading/updateIsLoading", false);
              this.modal.delete = false;
              this.errorToast(error);
            }
          }
        });
    },
    toggleCreate() {
      this.modal.create = true;
    },
    submitToggle: debounce(async (param, vm) => {
      await vm.$store.dispatch("integration/enableDisableIntegration", param);
      vm.successToast(
        param.status == true ? "Channel is active" : "Channel is deactive"
      );
      vm.fetchData();
    }, 300),
    toggleEdit(val) {
      // console.log("isi fb: ", val);
      this.formEdit = val;
      this.modal.edit = true;
      this.formEdit.integrationId = val.id;
    },
    editSubmit(data) {
      this.edit(data, this);
    },
    edit: debounce(async (param, vm) => {
      // API BELOW IS NO LONGER SUPPORTED FOR FB
      // try {
      //   //pake api update instagram, fungsinya kurang lebih sama dengan update facebook
      //   await vm.$axios.post(
      //     `api/${vm.$store.getters.appId.hashed}/update-facebook`,
      //     param
      //   );
      //   vm.formEdit = null;
      //   vm.modal.edit = false;
      //   vm.successToast("Facebook integration successfully updated");
      // } catch (err) {
      //   vm.formEdit = null;
      //   vm.modal.edit = false;
      //   vm.errorToast("Unable to update facebook integration");
      // }
    }, 300),
  },
  async mounted() {
    await this.fetchData();
    this.$store.dispatch("bot/botList");
    this.$store.dispatch("ticket/getBranchOffice");
    this.$store.dispatch("ticket/getAllGroupManagement", "");
  },
};
</script>

<style lang="scss" scoped></style>
